<template>
  <div class="global-setting">
    <div class="box">
      <el-form
        ref="themeColor"
        :model="submitData"
        label-width="200px"
        label-position="left"
      >
        <el-form-item label="按钮主题色">
          <!-- <el-input v-model="submitData.StoresName" size="small"></el-input> -->
          <el-color-picker
            v-model="submitData.BtnThemeColor"
            size="mini"
            @change="onChangeColor('BtnThemeColor', $event)"
          >
          </el-color-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            :disabled="submitDisabled"
            @click="saveGlobalData"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Wxmpdiy from "@/api/wxmpdiy.js";

export default {
  data() {
    return {
      // 提交数据
      submitData: {
        BtnThemeColor: "#663924", // 按钮主题色
      },
      submitDisabled: false, // 提交防抖
    };
  },

  mounted() {
    this.getGlobalData();
  },

  methods: {
    // 获取全局设置数据
    async getGlobalData() {
      let { BtnThemeColor } = this.submitData;
      try {
        let { data } = await Wxmpdiy.getGlobalData();
        this.submitData = {
          BtnThemeColor: data.BtnThemeColor || BtnThemeColor,
        };
      } catch (error) {
        console.log(error);
      }
    },

    // 保存全局设置数据
    async saveGlobalData() {
      this.submitDisabled = true;
      try {
        // console.log(this.submitData);
        let { errcode } = await Wxmpdiy.saveGlobalData(this.submitData);
        if (errcode == 0) {
          this.$message.success("保存成功!");
          this.getGlobalData();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 颜色选择器改变事件
    onChangeColor(keyName, value) {
      // if (keyName == "BackColor" && !value) {
      //   value = "rgba(255,255,255,1)";
      // }
      this.submitData[keyName] = value;
    },
  },
};
</script>

<style lang="less" scoped>
.global-setting {
  display: flex;
  align-items: center;
  flex-direction: column;
  .el-form {
    width: 720px;
    margin-top: 20px;
    margin: 0 auto;
    .el-input {
      width: 100%;
    }
  }
}
</style>
